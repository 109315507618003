import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouterWithConfig } from '@config/router.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import nbLocale from '@angular/common/locales/nb';
import {
  ffNgxAuthBundleProvider,
  FfNgxAuthOidcCustomStore,
  FfNgxDateParserFormatter,
  ffNgxFormErrorProvider,
  ffNgxLoadingScreenProvider,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
  NgbDateParserFormatter,
} from '@fagforbundet/ngx-components';
import {
  apiConfigurationProvider,
  requestBundleConfig,
} from '@config/request-bundle.config';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { controlErrorMessages } from './control-errors/control-error.config';
import { authBundleConfig } from '@config/auth-bundle.config';
import { authnConfig } from '@config/authn.config';
import { loadingScreenConfig } from '@config/loading-screen.config';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@core/title-strategies/page-title-strategy';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { cookieConsentServiceFactory } from '@core/factories/cookie-consent-service.factory';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { CookiebotConfig } from '@config/cookiebot.config';
import { ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';
import { provideHighlightOptions } from 'ngx-highlightjs';

registerLocaleData(nbLocale);

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConsentServiceFactory,
      deps: [CookieConsentService],
      multi: true,
    },
    provideHighlightOptions({
      fullLibraryLoader: () => import('highlight.js'),
    }),
    ngxCookiebotProvider(CookiebotConfig),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    apiConfigurationProvider(),
    ffNgxStorageBundleProvider(storageBundleConfig),
    ffNgxFormErrorProvider(controlErrorMessages),
    provideAnimations(),
    provideHttpClient(withInterceptors([])),
    provideRouterWithConfig(),
    ffNgxAuthBundleProvider(authnConfig, authBundleConfig),
    {
      provide: AbstractSecurityStorage,
      useClass: FfNgxAuthOidcCustomStore,
    },
    { provide: LOCALE_ID, useValue: 'nb' },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    { provide: NgbDateParserFormatter, useClass: FfNgxDateParserFormatter },
    ffNgxLoadingScreenProvider(loadingScreenConfig),
  ],
};
